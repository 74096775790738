import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"

function SliderList() {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          frontmatter: {
            featuredImage: { sourceInstanceName: { eq: "slider" } }
          }
        }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            frontmatter {
              title1
              title2
              copy
              featuredImage {
                childImageSharp {
                  gatsbyImageData(quality: 50, width: 900, layout: CONSTRAINED)
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    }
  `)
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    className: "h-screen",

    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplaySpeed: 6000,
          autoplay: true,
          dots: true,
        },
      },
    ],
  }
  const posts = data.allMdx.edges
  let id = 0
  return (
    <Slider {...settings}>
      {posts.map(({ node }) => {
        const image = getImage(
          node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
        )
        node.id = id++
        return (
          <div key={node.id}>
            <div className="relative grid items-center h-screen">
              <div className="z-10 col-start-1 row-start-1">
                <h1>
                  {node.frontmatter.title1}
                  <br />
                  {node.frontmatter.title2}
                </h1>
              </div>
              <GatsbyImage
                loading="eager"
                image={image}
                alt=""
                className="object-cover object-center h-full col-start-1 row-start-1 pointer-events-none"
              />
            </div>
          </div>
        )
      })}
    </Slider>
  )
}

export default SliderList
